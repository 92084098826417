<template>
  <section
    :class="{ 'cart--empty' : shopBaseUrl && showEmptyCart }"
    class="cart"
  >
    <main>
      <div
        v-if="cart?.items?.length"
        class="cart__wrapper"
      >
        <div class="cart__back">
          <NuxtLink
            v-if="shopBaseUrl"
            :to="shopBaseUrl"
          >
            <Icon name="material-symbols:arrow-back" />
            {{ $t('cart.continue_shopping') }}
          </NuxtLink>
        </div>

        <CartActiveItem :item="activeBike" />
        <div class="cart__items">
          <div class="cart__title">
            <h1>{{ $t('cart.title') }}</h1>
          </div>

          <CartItem
            v-for="item in cart.items"
            :key="item.uid"
            :item="item"
            @set-active-bike="setActiveBike"
          />
        </div>
      </div>

      <CartSummaryFloating v-if="cart?.items?.length" />
      <CartEmpty v-if="showEmptyCart" />
    </main>
  </section>
</template>

<script setup>
  import { computed, onMounted, ref, watch } from "vue";

  definePageMeta({
    layout: "non-grid",
  });

  const cart = useCart();
  const { gtag } = useGtag();
  const storeCode = useStoreCode();
  const runtimeConfig = useRuntimeConfig();
  const activeBike = ref(cart.value?.items ? cart.value?.items[0] : null);
  const cook = useCookie('CookieConsent');
  const shopBaseUrl = useState('shopBaseUrl', () => null);
  const cookieData = useState('cookieData', () => useCookie(runtimeConfig?.public?.SHOP?.COOKIE_NAME)?.value || null);
  const showEmptyCart = computed(() => typeof cookieData === 'undefined' || cookieData === null || cart?.value?.items?.length < 1);


  onMounted(async () => {
    window.addEventListener('CookiebotOnAccept', function () {
      console.log('on accept', window.Cookiebot);
      console.log(cook);
      resolve(window.Cookiebot);
    }, { once: true });

    window.addEventListener('CookiebotOnLoad', function () {
      console.log('on load', window.Cookiebot);
      console.log(cook);
      resolve(window.Cookiebot);
    }, { once: true });

    window.addEventListener('CookiebotOnConsentReady', function () {
      console.log('on CookiebotOnConsentReady', window.Cookiebot);
      console.log(cook);
      resolve(window.Cookiebot);
    }, { once: true });

    window.addEventListener('CookiebotOnDecline', function () {
      console.log('on decline', window.Cookiebot);
      console.log(cook);
      resolve(window.Cookiebot);
    }, { once: true });

    // Log all properties of the cookie object
    if (window.CookieControl?.Cookie) {
      const cookieObj = window.CookieControl.Cookie;
      console.log('Cookie properties:', Object.keys(cookieObj));
      console.log('Cookie values:', {
        name: cookieObj.name,
        consented: cookieObj.consented,
        declined: cookieObj.declined,
        changed: cookieObj.changed,
        // Add any other properties you see
      });
    }

    // Also log any available methods
    console.log('Available methods:',
        Object.getOwnPropertyNames(Object.getPrototypeOf(window.CookieControl?.Cookie || {}))
    );

    gtag('event', 'view_cart', {
      ecommerce: {
        currency: cart.value.prices?.grand_total?.currency || 'EUR',
        value: cart.value.prices?.grand_total?.value || 0,
        items: cart.value.items?.map(item => ({
          item_id: item.product.sku,
          item_name: item.product.name,
          price: item.prices?.row_total_including_tax?.value,
          quantity: item.quantity
        }))
      }
    })
  })

  watch(() => cart.value?.items, (newItems, oldItems) => {
      if (!oldItems) return;
      
      // Check for quantity changes
      const quantityChanges = newItems.filter(newItem => {
          const oldItem = oldItems.find(item => item.uid === newItem.uid);
          return oldItem && oldItem.quantity !== newItem.quantity;
      });

      // Process quantity changes
      quantityChanges.forEach(changedItem => {
          const oldItem = oldItems.find(item => item.uid === changedItem.uid);
          const quantityDelta = changedItem.quantity - oldItem.quantity;
          const eventName = quantityDelta > 0 ? 'add_to_cart' : 'remove_from_cart';
          const absoluteQuantityDelta = Math.abs(quantityDelta);

        gtag('event', eventName, {
          ecommerce: {
            currency: changedItem.prices.row_total_including_tax.currency,
            value: (changedItem.prices.row_total_including_tax.value / changedItem.quantity) * absoluteQuantityDelta,
            items: [{
              item_id: changedItem.configured_variant ? changedItem.configured_variant.sku : changedItem.product.sku ?? '',
              item_name: changedItem.product.name,
              price: changedItem.prices.row_total_including_tax.value / changedItem.quantity,
              quantity: absoluteQuantityDelta,
              variant: changedItem.configurable_options ? changedItem.configurable_options.map(option => `${option.option_label}: ${option.value_label}`).join(', ') : ''
            }]
          }
        })
      });
  }, { deep: true });

  function setActiveBike(updatedActiveItem) {
    cart.value?.items?.map(item => {
      if (updatedActiveItem.uid === item.uid) {
        activeBike.value = item;
      }
    });
  }
</script>

<style lang="scss" scoped>
  .cart {
    padding: 60px 30px;
    background: var(--body-bg-alt, $white);

    @include min-screen('medium') {
      padding: 60px 60px 180px;
    }

    &__back {
      margin-bottom: 15px;
      grid-column-end: span 2;

      @include min-screen('medium') {
        margin-bottom: auto;
        padding-bottom: 60px;
      }

      a {
        display: inline-flex;
        align-items: center;
        color: currentColor;
        text-decoration: none;
      }

      svg {
        margin-right: 10px;
        transition: all 150ms ease;
      }

      &:hover {
        svg {
          transform: translateX(-3px);
        }
      }
    }

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0;

      main {
        padding-bottom: 0;
      }
    }

    main {
      padding-left: 0;
      padding-right: 0;
      padding-top: 15px;

      @include min-screen('medium') {
        padding-top: 60px;
      }
    }

    &__title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      @include min-screen('medium') {
        padding: 0 0 50px;
      }
    }

    &__wrapper {
      margin-bottom: auto;

      @include min-screen('large') {
        display: grid;
        grid-column-gap: 100px;
        align-items: center;
        grid-template-columns: 1fr 2fr;
      }
    }

    &__items {
      padding-bottom: 50px;

      @include min-screen('large') {
        padding-bottom: 0;
      }
    }
  }
</style>
